<template>
    <div class="order-wrap">
        <van-nav-bar
            title="订单"
            :border="false"
            fixed
        />
        <div class="order-main-wrap">
            <div class="first-tab-wrap">
                <div class="first-tab-box">
                    <van-badge :content="UntreatedBuyNum"><div class="tab-item" :class="{'active':firstTabIndex==0}" @click="changeFirstTab(0)">买单</div></van-badge>
                    <van-badge :content="UntreatedSellNum"><div class="tab-item" :class="{'active':firstTabIndex==1}" @click="changeFirstTab(1)">卖单</div></van-badge>
                    <van-badge><div class="tab-item" :class="{'active':firstTabIndex==2}" @click="changeFirstTab(2)">挂单</div></van-badge>
                    <van-badge><div class="tab-item" :class="{'active':firstTabIndex==3}" @click="changeFirstTab(3)">API订单</div></van-badge>
                  <!--    比特币 tab start   -->
                  <van-badge>
                    <div
                        class="tab-item"
                        :class="{'active':firstTabIndex==4}"
                        @click="changeFirstTab(4)">
                      USDT
                    </div>
                  </van-badge>
                  <!--    比特币 tab end   -->
                </div>
            </div>
            <div class="second-tab-wrap" v-if="firstTabIndex<2">
                <div class="tab-item" :class="{'active':secondTabIndex==4}" @click="changeSecondTab(4)">全部</div>
                <div class="tab-item" :class="{'active':secondTabIndex==1}" @click="changeSecondTab(1)">进行中</div>
                <div class="tab-item" :class="{'active':secondTabIndex==2}" @click="changeSecondTab(2)">已完成</div>
                <div class="tab-item" :class="{'active':secondTabIndex==3}" @click="changeSecondTab(3)">已取消</div>
            </div>
            <div class="second-tab-wrap" v-if="firstTabIndex==2">
                <div class="tab-item" :class="{'active':threeTabIndex==''}" @click="changeThreeTab('')">全部</div>
                <div class="tab-item" :class="{'active':threeTabIndex==1}" @click="changeThreeTab(1)">出售中</div>
                <div class="tab-item" :class="{'active':threeTabIndex==5}" @click="changeThreeTab(5)">交易中</div>
                <div class="tab-item" :class="{'active':threeTabIndex==3}" @click="changeThreeTab(3)">已完成</div>
                <div class="tab-item" :class="{'active':threeTabIndex==2}" @click="changeThreeTab(2)">已取消</div>
            </div>
            <div class="second-tab-wrap" v-if="firstTabIndex==3">
                <div class="tab-item" :class="{'active':fourTabIndex==1}" @click="changeFourTab(1)">充值</div>
                <div class="tab-item" :class="{'active':fourTabIndex==2}" @click="changeFourTab(2)">提现</div>
                <div class="tab-item" :class="{'active':fourTabIndex==3}" @click="changeFourTab(3)">加款</div>
                <div class="tab-item" :class="{'active':fourTabIndex==4}" @click="changeFourTab(4)">扣款</div>
            </div>
            <!--    比特币 下级 tab start   -->
            <div class="second-tab-wrap" v-if="firstTabIndex==4">
                <div class="tab-item" :class="{'active':fiveTabIndex==1}" @click="changeFiveTab(1)">
                    USDT充值
                </div>
                <div class="tab-item" :class="{'active':fiveTabIndex==2}" @click="changeFiveTab(2)">
                    USDT提现
                </div>
            </div>
            <!--    比特币 下级 tab end   -->

            <div class="order-main-box">
                <div class="date-wrap">
                    <div class="date-box" @click="showDateCalendar=true">
                        <div class="date-item-box">
                            <van-image :src="themeImgs.commonCalendarIcon" />
                            <span class="date">{{startDate}}</span>
                            <van-image :src="themeImgs.commonArrowDownIcon" />
                        </div>
                        <p>至</p>
                        <div class="date-item-box">
                            <van-image :src="themeImgs.commonCalendarIcon" />
                            <span class="date">{{endDate}}</span>
                            <van-image :src="themeImgs.commonArrowDownIcon" />
                        </div>
                    </div>
                    <van-field
                            v-if="fourTabIndex<3"
                            v-model="apiParams.order_sn"
                            autocomplete="off"
                            placeholder="请输入搜索的订单编号">
                        <template #button>
                            <van-button size="small" @click="handleSearch">搜索</van-button>
                        </template>
                    </van-field>
                </div>
                <div class="order-content-wrap" v-if="orderList.length>0">
                    <van-list
                        v-if="firstTabIndex<2"
                        v-model:loading="loadingMore"
                        :finished="finishedMore"
                        finished-text="没有更多了"
                        @load="onLoadMore"
                        :immediate-check="false"
                    >
                        <router-link :to="{path:firstTabIndex==0?'buyerOrderDetails':'sellerOrderDetails',query:{order_sn:item.order_sn}}" class="order-item-box" v-for="(item,index) in orderList" :key="index">
                            <div class="ordersn-box">
                                <span>订单编号：{{item.order_sn}}</span>
                                <van-image :src="themeImgs.commonCopyIcon" @click.prevent="handleCopy(item.order_sn)" />
                            </div>
                            <div class="ordersn-box">
                                <span>挂单编号：{{item.order}}</span>
                                <van-image :src="themeImgs.commonCopyIcon" @click.prevent="handleCopy(item.order)" />
                            </div>
                            <div class="item-box">
                                <div class="left-box">
                                    <span class="amount">{{item.currency}}</span>
                                    <span class="unit">{{store.state.publicConfig.title}}</span>
                                </div>
                                <div class="right-box">
                                    <span class="status">{{orderStatusFilter(Number(item.status))}}</span>
                                    <span class="date">{{item.created_at}}</span>
                                </div>
                            </div>
                        </router-link>
                    </van-list>
                    <van-list
                        v-if="firstTabIndex==2"
                        v-model:loading="loadingMore"
                        :finished="finishedMore"
                        finished-text="没有更多了"
                        @load="onLoadMore"
                        :immediate-check="false"
                    >
                        <div class="order-item-box" v-for="(item,index) in orderList" :key="index">
                            <div class="ordersn-box">
                                <span>订单编号：{{item.order_sn}}</span>
                                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(item.order_sn)" />
                            </div>
                            <div class="item-box">
                                <div class="left-box">
                                    <span class="amount">{{item.currency}}</span>
                                    <span class="unit">{{store.state.publicConfig.title}}</span>
                                </div>
                                <div class="right-box">
                                    <div class="status-btn-box">
                                        <span class="status">{{hangOrderStatusFilter(Number(item.status))}}</span>
                                        <van-button v-if="item.status==1" class="cancle-btn" type="danger" :disabled="btnClock" round @click="handleCancelSell(item.id)">下架</van-button>
                                    </div>
                                    <span class="date">{{item.created_at}}</span>
                                </div>
                            </div>
                            <div class="ordersn-box">
                                <span>总出售：{{item.count_currency}}</span>
                                <span>已完成：{{item.traded_currency}}</span>
                                <span>交易中：{{item.freeze_currency}}</span>
                            </div>
                        </div>
                    </van-list>
                    <van-list
                        v-if="firstTabIndex==3"
                        v-model:loading="loadingMore"
                        :finished="finishedMore"
                        finished-text="没有更多了"
                        @load="onLoadMore"
                        :immediate-check="false"
                    >
                        <div class="order-item-box" v-for="(item,index) in orderList" :key="index">
                            <div class="ordersn-box" v-if="fourTabIndex<3">
                                <span>订单编号：{{item.order}}</span>
                                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(item.order_sn)" />
                            </div>
                            <div class="item-box">
                                <div class="left-box">
                                    <span class="amount">{{fourTabIndex<3 ? item.amount : item.currency}}</span>
                                    <span class="unit">{{store.state.publicConfig.title}}</span>
                                </div>
                                <div class="right-box">
                                    <span class="status" v-if="fourTabIndex<3">{{apiOrderStatusFilter(Number(item.status))}}</span>
                                    <span class="status" v-if="fourTabIndex==3">人工加款</span>
                                    <span class="status" v-if="fourTabIndex==4">人工扣款</span>
                                    <span class="date">{{item.created_at}}</span>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    <!--    比特币列表   start   -->
                    <van-list
                            v-if="firstTabIndex==4"
                            v-model:loading="loadingMore"
                            :finished="finishedMore"
                            finished-text="没有更多了"
                            @load="onLoadMore"
                            :immediate-check="false"
                    >
                        <div class="order-item-box" v-for="(item,index) in orderList" :key="index">
                            <div class="ordersn-box">
                                <span>
                                    订单号：{{ item.order_sn }}
                                    <van-image
                                            class="copy-address-icon"
                                            :src="themeImgs.commonCopyIcon"
                                            @click="handleCopy(item.order_sn)"
                                    />
                                </span>
                            </div>
                            <div class="item-box">
                                <div class="left-box">
                                    <span class="amount">{{ item.amount }}</span>
                                    <span class="currency">USDT</span>
                                </div>
                                <div
                                        v-show="fiveTabIndex === 1 || (fiveTabIndex === 2 && item.status === 3)"
                                        class="middle-box"
                                        @click="handleCertificate(item.pic_data)">
                                    查看凭证
                                </div>
                                <div class="right-box">
                                    <div style="display: flex; align-items: center;">
                                        <span class="status" :class="{'error-status': item.status === 4}">
                                            {{ filterBitRechargeStatus(item.status) }}
                                        </span>
                                        <van-icon
                                                v-show="item.status === 4"
                                                :name="themeImgs.orderBitFailIcon"
                                                class="fail-icon"
                                                @click="handleBitFailDialog(item.content)"
                                        />
                                    </div>
                                    <span class="date">{{ item.created_at }}</span>
                                </div>
                            </div>
                        </div>
                    </van-list>
                    <!--    比特币列表   end   -->
                    <van-empty
                            v-else-if="orderList.length === 0"
                            class="custom-image"
                            :image="themeImgs.commonEmptyImg"
                            description="暂无数据～"
                    />
                </div>
                <van-empty
                    v-else
                    class="custom-image"
                    :image="themeImgs.commonEmptyImg"
                    description="暂无数据～"
                />
            </div>
        </div>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
        <Footer />
    </div>
</template>

<script>
import { reactive, ref, onMounted,computed, watch } from 'vue'
import Footer from "@/components/Footer"
import {
  getBuyOrderLists,getSellOrderLists,getSellOrderDealLists,getApiOrderLists,getOrderRemind,
  doCancelSellOrder, getBitRechargeOrder, getBitWithdrawOrder} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import { Toast, Dialog, ImagePreview } from 'vant'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
    components: {
        Footer
    },
    setup () {
        const route = useRoute()
        const handleBitFailDialog = (content) => {
            Dialog({
              title: '失败原因',
              message: content,
              className: 'take-down-dialog'
            })
          }
        const store = useStore()

        // 存储未处理买单的数量
        const UntreatedBuyNum = ref()
        // 存储未处理卖单的数量
        const UntreatedSellNum = ref()
        // 获取订单状态提醒
        const handleGetOrderRemind = () => {
          //    name === bitcoin,比特币消息，不需要调用接口
          if (orderRemindObj.value.name !== 'bitcoin') {
            getOrderRemind().then(res => {
              if(res.result == 200000) {
                if(res.data.buy_count>0) {
                  UntreatedBuyNum.value = res.data.buy_count
                } else {
                  UntreatedBuyNum.value = ""
                }
                if(res.data.sell_count>0) {
                  UntreatedSellNum.value = res.data.sell_count
                } else {
                  UntreatedSellNum.value = ""
                }
              }
            })
          }
        }
        // 计算属性实现视图更新--新订单提醒
        const orderRemindObj = computed(() => store.state.orderRemind)
        watch(orderRemindObj, (newValue) =>{
            handleGetOrderRemind()
          //  比特币订单的操作
          if (newValue.name === 'bitcoin') {
            if (newValue.type === 1) {
              fiveTabIndex.value = 1
              handleGetBitRechargeOrderList()
            }
            if (newValue.type === 2) {
              fiveTabIndex.value = 2
              handleGetBitWithdrawOrderList()
            }
          }
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 第一层tab索引值
        const firstTabIndex = ref(localStorage.getItem('firstTabIndex') ? localStorage.getItem('firstTabIndex') : route.query.bitType ? 4 : 0)
        // 第二层tab索引值
        const secondTabIndex = ref(4)
        // 第三层tab索引值
        const threeTabIndex = ref('')
        // 第四层tab索引值
        const fourTabIndex = ref(1)

        // 比特币tab索引值
        const fiveTabIndex = ref(1)
        // 点击第一层tab
        const changeFirstTab = id => {
            firstTabIndex.value = id
            secondTabIndex.value = 4
            threeTabIndex.value = ''
            fourTabIndex.value = 1
            fiveTabIndex.value = 1
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            if (id == 4) {
              handleGetBitRechargeOrderList()
            } else if(id==0) {
                handleGetBuyOrderList()
            } else if(id==1) {
                handleGetSellOrderDealList()
            } else if(id==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 点击第二层tab
        const changeSecondTab = id => {
            secondTabIndex.value = id
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            }
        }
        // 点击第三层tab
        const changeThreeTab = id => {
            threeTabIndex.value = id
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            handleGetSellOrderList()
        }
        // 点击第四层tab
        const changeFourTab = id => {
            fourTabIndex.value = id
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            handleGetApiOrderList()
        }

        //  点击比特币tab索引
        const changeFiveTab = id => {
          fiveTabIndex.value = id
          startDate.value = moment().format("YYYY-MM-DD")
          endDate.value = moment().format("YYYY-MM-DD")
          apiParams.pageSize = 20
          apiParams.order_sn = ''
          orderList.value = []
          finishedMore.value = false
          if (id === 1) {
            handleGetBitRechargeOrderList()
          }
          if (id === 2) {
            handleGetBitWithdrawOrderList()
          }
        }

        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            orderList.value = []
            apiParams.pageSize = 20
            finishedMore.value = false
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            if (firstTabIndex.value === 4) {
                if (fiveTabIndex.value === 1) {
                  handleGetBitRechargeOrderList()
                }
                if (fiveTabIndex.value === 2) {
                  handleGetBitWithdrawOrderList()
                }
            } else if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 存储订单列表数据
        const orderList = ref([])
        // 存储接口默认请求参数
        const apiParams = reactive({
            type: 1,
            order_sn: '',
            page: 1,
            pageSize: 20,
            start: `${startDate.value} 00:00:00`,
            end: `${endDate.value} 23:59:59`
        })
        // 获取买家订单列表数据
        const handleGetBuyOrderList = (more) => {
            apiParams.type = secondTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getBuyOrderLists(apiParams).then(res => {
              if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
              }
            }).catch(() => {
                orderList.value = []
                loadingMore.value = false
            })
        }
        // 获取卖家卖单列表数据
        const handleGetSellOrderDealList = (more) => {
            apiParams.type = secondTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getSellOrderDealLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
                }
            }).catch(() => {
              orderList.value = []
              loadingMore.value = false
            })
        }
        // 获取卖家挂单列表数据
        const handleGetSellOrderList = (more) => {
            apiParams.status = threeTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.type
            if(more) {
                apiParams.pageSize+=20
            }
            getSellOrderLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
                }
            }).catch(() => {
              orderList.value = []
              loadingMore.value = false
            })
        }
        // 获取API订单列表数据
        const handleGetApiOrderList = (more) => {
            apiParams.type = fourTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.status
            if(more) {
                apiParams.pageSize+=20
            }
            getApiOrderLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list.list
                    } else {
                        orderList.value = res.data.list.list
                        loadingMore.value = false
                        if(res.data.list.list.length>=res.data.list.count) {
                            finishedMore.value = true
                        }
                    }
                }
            }).catch(() => {
              orderList.value = []
              loadingMore.value = false
            })
        }
        //  获取比特币充值订单列表数据
        const handleGetBitRechargeOrderList = (more) => {
            apiParams.type = fiveTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.status
            if(more) {
              apiParams.pageSize+=20
            }
            getBitRechargeOrder(apiParams).then(res => {
              if(res.result == 200000) {
                if(!more) {
                  orderList.value = res.data.list
                } else {
                  orderList.value = res.data.list
                  loadingMore.value = false
                  if(res.data.list.length>=res.data.count) {
                    finishedMore.value = true
                  }
                }
              }
            }).catch(() => {
              orderList.value = []
              loadingMore.value = false
            })
        }
        //  获取比特币提现订单列表数据
        const handleGetBitWithdrawOrderList = (more) => {
            apiParams.type = fiveTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.status
            if(more) {
              apiParams.pageSize+=20
            }
            getBitWithdrawOrder(apiParams).then(res => {
              if(res.result == 200000) {
                    if(!more) {
                          orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
              }
            }).catch(() => {
              orderList.value = []
              loadingMore.value = false
            })
        }
        onMounted(() => {
            handleGetOrderRemind()
            //  比特币tab
            console.log(firstTabIndex.value)
            if (firstTabIndex.value == 4) {
              if (route.query.bitType) {
                fiveTabIndex.value = +route.query.bitType
              }
              if (route.query.bitType === '2') {
                handleGetBitWithdrawOrderList()
              } else {
                handleGetBitRechargeOrderList()
              }
              //    提现提交跳转进来
              if (route.query.bitType) {
                fiveTabIndex.value = parseInt(route.query.bitType)
              }
            } else if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        })
        // 订单状态过滤器
        const orderStatusFilter = (status) => {
            if(status<5 || status==10) {
                return '进行中'
            }
            if(status==5 || status==9) {
                return '已完成'
            }
            if(status==6 || status==7 || status==8) {
                return '已取消'
            }
            if (status == 12) {
                return '人工退回'
            }
            if (status == 13) {
                return '人工打币'
            }
          if (status == 14) {
            return '系统冻结'
          }
        }
        // 挂单状态过滤器
        const hangOrderStatusFilter = (status) => {
            if(status==1) {
                return '出售中'
            }
            if(status==2) {
                return '已取消'
            }
            if(status==3) {
                return '已完成'
            }
            if(status==4) {
                return '已冻结'
            }
            if(status==5) {
                return '交易中'
            }
        }
        // api订单状态过滤器
        const apiOrderStatusFilter = (status) => {
            if(status==1) {
                return '待支付'
            }
            if(status==2) {
                return '成功'
            }
            if(status==3) {
                return '失败'
            }
        }

        //  比特币充值/提现订单状态过滤器
        const filterBitRechargeStatus = (status) => {
          if ([1, 2].includes(status)) {
            return '处理中'
          }
          if (status === 4) {
            return '拒绝'
          }
          return '已完成'
        }

        //  查看凭证
        const handleCertificate = (url) => {
            if (!url) {
              return false
            }
          ImagePreview([url])
        }
        // 上拉加载
        const loadingMore = ref(false);
        const finishedMore = ref(false);
        const onLoadMore = () => {
            if (firstTabIndex.value==4) {
              if (fiveTabIndex.value === 1) {
                handleGetBitRechargeOrderList(true)
              }
              if (fiveTabIndex.value === 2) {
                handleGetBitWithdrawOrderList(true)
              }
            } else if(firstTabIndex.value==0) {
                handleGetBuyOrderList(true)
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList(true)
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList(true)
            } else {
                handleGetApiOrderList(true)
            }
        };
        // 复制
        const { toClipboard } = useClipboard()
        const handleCopy = async(content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 搜索
        const handleSearch = () => {
            orderList.value = []
            apiParams.page = 1
            apiParams.pageSize = 20
            finishedMore.value = false
            if (firstTabIndex.value==4) {
              if (fiveTabIndex.value === 1) {
                handleGetBitRechargeOrderList()
              }
              if (fiveTabIndex.value === 2) {
                handleGetBitWithdrawOrderList()
              }
            } else if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 下架挂单
        const handleCancelSell = id => {
            if (btnClock.value) return
            Dialog.confirm({
                title: '温馨提示',
                message:'确认将该笔订单进行下架吗？',
                className: 'take-down-dialog'
            }).then(() => {
                btnClock.value = true
                doCancelSellOrder({id:id}).then(res=>{
                    btnClock.value = false
                    if(res.result==200000) {
                        Toast.success('下架成功')
                        handleGetSellOrderList()
                    } else {
                        Toast.fail(res.msg)
                    }
                }).catch(error => {
                    btnClock.value = false
                    Toast.fail('操作失败，请检查网络重试或者联系客服')
                })
            }).catch(() => {
                // on cancel
            });
        }
        onBeforeRouteLeave((to, from, next) => {
            if(to.path.includes('OrderDetails')) {
                window.localStorage.setItem('firstTabIndex',firstTabIndex.value)
                next()
            } else {
                window.localStorage.removeItem('firstTabIndex')
                next()
            }
        })
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            firstTabIndex,
            secondTabIndex,
            threeTabIndex,
            fourTabIndex,
            changeFirstTab,
            changeSecondTab,
            changeThreeTab,
            changeFourTab,
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            orderList,
            orderStatusFilter,
            hangOrderStatusFilter,
            loadingMore,
            finishedMore,
            onLoadMore,
            handleCopy,
            handleSearch,
            apiParams,
            apiOrderStatusFilter,
            UntreatedBuyNum,
            UntreatedSellNum,
            handleCancelSell,
            btnClock,
            themeImgs,
            store,
            handleBitFailDialog,
            changeFiveTab,
            fiveTabIndex,
            filterBitRechargeStatus,
            handleCertificate
        }
    }
}
</script>

<style lang="less" scoped>
.order-wrap {
    .order-main-wrap {
        position: fixed;
        top: 50px;
        bottom: 50px;
        width: 100%;
        background: var(--mainBgColor);
        .first-tab-wrap {
            height: 60px;
            background: var(--headBgColor);
            padding: 0 15px;
            .first-tab-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 32px;
                padding: 2px 3px;
                background: var(--boxBgColor1);
                border-radius: 8px;
                color: var(--textCommonColor);
                .van-badge__wrapper {
                    /*width: 80px;*/
                    /*height: 28px;*/
                    /*line-height: 28px;*/
                    text-align: center;
                    :deep(.van-badge) {
                        z-index: 1;
                    }
                    .tab-item {
                        padding: 3px 10px;
                        border-radius: 8px;
                    }
                    &:first-child {
                        .tab-item {
                            /*border-radius: 8px 0px 0px 8px;*/
                        }
                    }
                    &:last-child {
                        .tab-item {
                            /*border-radius: 0px 8px 8px 0px;*/
                        }
                    }
                    .tab-item.active {
                        background: var(--orderTabActiveBgColor);
                        color: var(--textColor);
                    }
                }
            }
        }
        .second-tab-wrap {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: var(--headBgColor);
            .tab-item {
                height: 36px;
                line-height: 36px;
                color: var(--textCommonColor);
                &.active {
                    color: var(--textColor);
                    font-weight: 600;
                }
            }
        }
        .order-main-box {
            height: calc(100vh - 46px - 60px - 36px - 50px);
            background: var(--mainBgColor);
            overflow: hidden;
            .date-wrap {
                padding: 12px;
                .date-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 36px;
                    padding: 0 20px;
                    background: var(--boxBgColor1);
                    border-radius: 17px;
                    color: var(--textCommonColor);
                    .date-item-box {
                        display: flex;
                        align-items: center;
                        .van-image {
                            width: 16px;
                            height: 16px;
                        }
                        p {
                            font-size: 16px;
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                        .date {
                            padding: 0 10px;
                            font-size: 16px;
                            font-family: 'DINOffcPro';
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                    }
                }
                .van-cell {
                    margin-top: 10px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 0;
                    background: var(--orderSearchNumInputBgColor);
                    border-radius: 17px;
                }
                /*::v-deep {*/
                /*    .van-field__body {*/
                /*        height: 36px;*/
                /*    }*/
                /*    .van-field__control {*/
                /*        width: 248px;*/
                /*        height: 100%;*/
                /*    }*/
                /*    .van-field__clear {*/
                /*        width: 36px;*/
                /*        height: 36px;*/
                /*        display: flex;*/
                /*        align-items: center;*/
                /*        justify-content: center;*/
                /*        position: absolute;*/
                /*        right: 54px;*/
                /*        padding: 0;*/
                /*    }*/
                /*    .van-field__button {*/
                /*        position: absolute;*/
                /*        right: 0;*/
                /*        padding: 0;*/
                /*    }*/
                /*}*/
            }
            .order-content-wrap {
                height: 100%;
                padding: 0 12px 106px;
                overflow: scroll;
                .order-item-box {
                    display: block;
                    padding: 8px 0;
                    border-bottom: 1px solid #eee;
                    color: var(--textCommonColor);
                    &:last-child {
                        border-bottom: none;
                    }
                    .ordersn-box {
                        display: flex;
                        align-items: center;
                        padding: 8px 0;
                        span {
                            font-weight: 500;
                            margin-right: 7px;
                        }
                        .van-image {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .item-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left-box {
                            display: flex;
                            align-items: center;
                            span {
                                line-height: 26px;
                            }
                            .amount {
                                margin-right: 4px;
                                font-size: 20px;
                            }
                        }
                        .middle-box {
                            color: var(--textColor);
                        }
                        .right-box {
                            display: flex;
                            flex-direction: column;
                            align-items: end;
                            .status-btn-box {
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                            }
                            .status {
                                margin-right: 10px;
                                font-size: 16px;
                                &.error-status {
                                    color: red;
                                }
                            }
                            .cancle-btn {
                                height: 30px;
                                padding: 0 10px;
                                font-size: 16px;
                            }
                            .date {
                                opacity: 0.5;
                            }
                            .fail-icon {
                                ::v-deep {
                                    .van-icon__image {
                                        width: auto;
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 750px) {
        .order-main-wrap {
            width: 375px;
        }
    }
}
.collection-img {
    width: 100%;
    img {
        width: 100%;
        height: auto;
    }
}
</style>
<style lang="less">
.take-down-dialog {
    .van-dialog__header {
        color: #fff;
    }
}
</style>
